import { Button, Table, Space, Tag, Modal, Form, Input, Radio, notification } from "antd";
import { useEffect, useState } from "react";
import Text from "antd/es/typography/Text";
import { ERROR, SUCCESS, updateFormValue } from "./common";
import { Can } from "../../permissions";
import PasswordEntry from "../widgets/forms/password";

function roleIndexToLabel(index: number) {
  switch (index) {
    case 0:
      return <Tag color='volcano' key={index}>Super</Tag>;
    case 1:
      return <Tag color='geekblue' key={index}>Uploader</Tag>;
    case 2:
      return <Tag color='green' key={index}>User Admin</Tag>;
    case 3:
      return <Tag color='orange' key={index}>Char Admin</Tag>;
    case 4:
      return <Tag color='black' key={index}>Facility Admin</Tag>;
    case 5:
      return <Tag color='cyan' key={index}>Auditor</Tag>;
    default:
      return <></>;
  }
}

function deleteUser(user_id: string, username: string, refreshCallback: () => void) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    },
    body: JSON.stringify({id: user_id}),
  };
  fetch(`/api/user/${username}/delete`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 400) {
        notification.error({
          message: 'Error deleting user',
          description: 'Error deleting user - bad request. Try reloading the page and attempting again.',
          duration: 4,
          placement: "top",
        })
        return null;  
      }
      notification.error({
        message: 'Error deleting user',
        description: 'Error deleting user',
        duration: 4,
        placement: "top",
      });
      return null;
    })
    .then(data => {
      if (data !== null && data.status === 1) {
        refreshCallback();
      }
    })
    .catch(err => {
      console.log("error " + err);
    });
}

function UserTable(props: any) {
  const cols = [
    {
      title: "User ID",
      dataIndex: "uid",
      key: "uid",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (_:any, record: any) => (
        <>
          {roleIndexToLabel(record.role)}
        </>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: any) => (
        <Space size="small">
          <Can I='delete' a='user'>
            <Button type="dashed" onClick={() => deleteUser(record.id, record.uid, () => props.refreshCallback())}>Delete</Button>
          </Can>
          <Can I='update' a='user'>
            <Button type="dashed" onClick={() => props.showEditAction(record)}>Edit</Button>
          </Can>
        </Space>
      ),
    },
  ];
  
  return (
    <Table dataSource={props.users} columns={cols} />
  )
}

function UserAdmin(props : any) {
  const defaultUserFormContent : any = {
    id: null,
    edit: false,
    username: "",
    username_status: ERROR,
    pass: "",
    role: 0,
    roleChosen: false,
    allValid: false,
  };
  const [userFormContent, setUserFormContent] = useState<any>(defaultUserFormContent);
  const [passValid, setPassValid] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [users, setUsers] = useState<any[]>([]);
  const [usedUsernames, setUsedUsernames] = useState<string[]>([]);
  const [showUserEdit, setShowUserEdit] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<any>({
    user: "",
    role: -1,
    trigger: 0
  });

  useEffect(() => {
    executeSearch();
  }, [searchParams])

  const executeSearch = async () => {
    await fetch('/api/users', {
      headers: {
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        },
    }).then(response => {
        if (response.ok) {
          return response.json();
        }
        notification.error({
          message: 'Error retrieving users.',
          description: 'An unknown error occurred retrieving the users. Please try again later.',
          duration: 4,
          placement: "top",
        });
        return null;
      })
      .then(data => {
        if (data !== null) {
          setUsers(data.users);
          setUsedUsernames(data.users.map((r: any) => r.uid)); // one day, this may need to become more efficient 
        }
      })
      .catch(err => {
        console.log("error " + err);
      });
  }

  const handleOk = () => {
    const requestOptions = {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
      },
      body: JSON.stringify({
        id: userFormContent.id,
        uname: userFormContent.username,
        pass: userFormContent.pass,
        role: userFormContent.role,
      }),
    };
    const url : string = userFormContent.edit ? `/api/user/edit` :  '/api/user/add';
    fetch(url, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 400) {
          notification.error({
            message: 'Error persisting user.',
            description: 'Bad request, please check your inputs.',
            duration: 4,
            placement: "top",
          });
          setConfirmLoading(false);
          return null;
        }
        else {
          notification.error({
            message: 'Error persisting user.',
            description: 'Unknown server error. Try again later or contact support.',
            duration: 4,
            placement: "top",
          });
          closeModal();
        }
      })
      .then(data => {
        if (data !== null && data.status === 1) {
          closeModal();
          refresh();
        }
      })
      .catch(err => {
        console.log("Uncaught error! " + err);
        closeModal();
      });
  }

  const closeModal = () => {
    setConfirmLoading(false);
    setShowUserEdit(false);
  };

  const handleCancel = () => {
    closeModal();
  }

  const showUserFormAsEdit = (record: any) => {
    setUserFormContent({
      id: record.id,
      edit: true,
      username: record.uid,
      username_status: SUCCESS,
      pass: null, // this must be null to allow user to not edit it
      role: record.role,
      roleChosen: true,
      allValid: true,
    });
    setShowUserEdit(true);
  }

  const handleUsernameChange = (e: any) => {
    updateFormValue(setUserFormContent, userFormContent, "username", e.target.value, usedUsernames.includes(e.target.value) || !/^[a-zA-Z0-9._]+$/.test(e.target.value) || e.target.value.length < 8 ? ERROR : SUCCESS)
  }

  const handleRoleChange = (e: any) => {
    updateFormValue(setUserFormContent, userFormContent, "role", e.target.value, SUCCESS);
  }

  // a hack to cause a change to the search data, so a re-search is triggered to populate
  // the displayed table.
  function refresh(): void {
    setSearchParams({
      ...searchParams, 
      trigger: searchParams.trigger + 1});
  }

  function updatePass(pass: string) {
    setUserFormContent({
      ...userFormContent,
      pass: pass,
    });
  }

  return (
  <div className="wrapper">
    <Space size="middle">
      <h1>User Admin</h1>
      <Can I='create' a='user'>
        <Button onClick={() => {
          setUserFormContent(defaultUserFormContent);
          setShowUserEdit(true);
        }}>New User</Button>
      </Can>
    </Space>
    <UserTable users={users} refreshCallback={refresh} showEditAction={showUserFormAsEdit}/>
    <Modal
      title="User"
      open={showUserEdit}
      onOk={handleOk}
      okButtonProps={{disabled: !((userFormContent.allValid && passValid) || (userFormContent.edit && userFormContent.pass === null && userFormContent.username_status === SUCCESS && userFormContent.roleChosen))}}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}>
      <Text type="secondary" hidden={!userFormContent.edit}>Leave password fields blank to leave unchanged, regardless of validation error highlight.</Text>
      <Form labelCol={{ span: 12 }} wrapperCol={{ span: 16 }} style={{ maxWidth: 1000 }}>
        <Form.Item label='Username' required={true} validateStatus={userFormContent.username_status} tooltip="Unique username with which the user will login." >
          <Input 
            disabled={userFormContent.edit}
            value={userFormContent.username} 
            onChange={handleUsernameChange}/>
        </Form.Item>

        <PasswordEntry setOuterPasswordReference={updatePass} signalPassValid={setPassValid} />
        
        <Form.Item label="Role">
          <Radio.Group onChange={handleRoleChange} value={userFormContent.role}>
            <Space direction="vertical">
              <Radio value={0}>Super Admin</Radio>
              <Radio value={1}>Uploader</Radio>
              <Radio value={2}>User Admin</Radio>
              <Radio value={3}>Char Admin</Radio>
              <Radio value={4}>Facility Admin</Radio>
              <Radio value={5}>Auditor</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>    
      </Form>
    </Modal>
  </div>
  );
}

export default UserAdmin;